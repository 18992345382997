<template>
  <div class="header-four">
    <section class="section pt-5 pb-0 mt-4">
      <div class="container-fluid mt-md-1 px-0 px-md-3">
        <div class="rounded bg-light py-5 px-3 px-sm-0">
          <div class="row">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-7 col-md-6 col-12">
                  <div class="title-heading mr-lg-4">
                    <div class="alert alert-success bg-success alert-pills shadow" role="alert">
                      <span class="content">{{props.alert}}</span>
                    </div>

                    <h1 class="heading mb-3" v-html="props.title"></h1>
                    
                    <p class="para-desc text-muted">{{props.subtitle}}</p>
                  </div>
                </div>

                <div class="col-lg-5 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                  <div class="position-relative">
                    <img v-bind:src="props.imageURL" class="rounded img-fluid mx-auto d-block" alt="Video image URL" />
                    <div class="play-icon">
                      <a href="javascript: void(0);" v-b-modal.modal class="play-btn video-play-icon">
                        <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                      </a>
                      <b-modal id="modal" hide-footer size="lg" header-close-variant="white" header-class="border-0"
                        content-class="border-0" centered>
                        <vimeo-player ref="player" :player-width="750" :player-height="450" :video-id="287684225" />
                      </b-modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { SearchIcon } from "vue-feather-icons";
  export default {
    components: {
      SearchIcon
    },
    props: {
      props: {
        type: Object,
        default: function() {
          return {};
        }
      }
    }
  };
</script>
